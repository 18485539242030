<template>
  <div class="PersonalLogin">
    <v-row>
      <v-col
        cols="12"
        sm="10"
        md="6"
        lg="4"
      >
        <div v-if="!showCodigo">
          <v-text-field
            class="mb8"
            :disabled="cargando"
            filled
            hint="Ingresa tu número celular a 10 dígitos"
            label="Teléfono"
            :loading="cargando"
            pattern="\d*"
            persistent-hint
            prepend-inner-icon="phone"
            v-mask="'##########'"
            v-model="telefono"
            @keyup.native.enter="_doAwsLogin"
          />
          <v-btn
            color="primary"
            :disabled="cargando"
            :loading="cargando"
            @click="_doAwsLogin"
          >
            Iniciar sesión
            <v-icon>chevron_right</v-icon>
          </v-btn>
        </div>
        <div v-if="showCodigo">
          <small>Dentro de poco recibirás un mensaje de texto con un <strong>código de 6 dígitos</strong></small>
          <v-otp-input
            background-color="rgba(0, 0, 0, 0.06)"
            :disabled="cargando"
            v-model="otp"
            @finish="_sendOTP"
            @keyup.native.enter="_sendOTP"
            class="mb8 mt8"
          />
          <v-btn
            color="primary"
            :disabled="cargando"
            :loading="cargando"
            @click="_sendOTP"
          >
            Enviar código
            <v-icon>chevron_right</v-icon>
          </v-btn><br><br>
          <v-btn
            text
            outlined
            :disabled="timerCount>=1?true:false"
            @click="_doAwsLogin"
          >
            {{timerCount < 1 ? 'Enviar un nuevo código' : 'Solicita un nuevo código '+timerCount+'s'}}
          </v-btn>
          <br><br>
          <v-btn
            text
            small
            @click="telefono=null;otp=null;showCodigo=false;"
          >
            Cambiar número de teléfono
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { Auth } from "aws-amplify";
import forms from "@/mixins/forms.js";
export default {
  name: "PersonalLogin",
  mixins: [forms],
  data() {
    return {
      telefono: "5554656601",
      otp: null,
      cargando: false,
      showCodigo: false,
      user: Object,
      timerCount: 30,
    };
  },
  methods: {
    async _doPersonalLogin() {
      try {
        console.log("inside try");
        this.cargando = true;
        const data = {
          a: this.telefono,
          b: this.user.username,
        };
        console.log(data);
        const { body } = await this.$http.post("personal/sesion", data);
        if ("token" in body) {
          const { token, nombre, tipo, remunerado, voluntario } = body;
          this.$emit("msg", "HOLA " + nombre.toUpperCase());
          this.$session.start();
          delete this.$headers.headers.Token;
          this.$headers.headers.Token = token;
          this.$session.set("nombre", nombre);
          this.$session.set("token", token);
          this.$session.set("remunerado", remunerado);
          this.$session.set("voluntario", voluntario);
          this.$session.set("tipo", tipo);
          this.$emit("login", true);
        } else {
          this.$emit("msg", "Código no encontrado");
        }
      } catch (err) {
        console.dir("error en _doPersonalLogin");
        console.dir(err);
        this.cargando = false;
        this._handleError(err);
      }
    },
    async _doAwsLogin() {
      try {
        const {
          body: { exist },
        } = await this.$http.post("personal/sesion", {
          a: this.telefono,
        });
        exist === true
          ? this._askForOtpCode()
          : this.$emit("msg", "Usuario no encontrado");
      } catch (err) {
        this._handleError(err);
      }
    },
    async _askForOtpCode() {
      console.log("asking for otp code");
      this.cargando = true;
      try {
        await Auth.signOut();
      } catch (err) {
        this.cargando = false;
      }
      try {
        const user = await Auth.signIn("+52" + this.telefono, "cruzroja");
        this.user = user;
        const challengeName = user.challengeName;
        console.dir(user);
        this.cargando = false;
        if (challengeName === "NEW_PASSWORD_REQUIRED") this._newPassword(user);
        if (challengeName == "SMS_MFA") {
          this.showCodigo = true;
          this.timerCount = 30;
          // this.
        }
      } catch (error) {
        this.cargando = false;
        this.$emit("msg", "Sucedió un error al iniciar sesión");
      }
    },
    _newPassword(user) {
      const { requiredAttributes } = user.challengeParam;
      Auth.completeNewPassword(user, "cruzroja")
        .then((user) => {})
        .catch((e) => {});
      this._doAwsLogin();
    },
    async _sendOTP() {
      this.cargando = true;
      try {
        const user = await Auth.confirmSignIn(this.user, this.otp, "SMS_MFA");
        this.user = user;
        console.log("otp response");
        console.dir(user);
        this.user = user;
        this._doPersonalLogin();
      } catch (error) {
        this.cargando = false;
      }
    },
  },
  watch: {
    timerCount: {
      handler(value) {
        if (value > 0)
          setTimeout(() => {
            this.timerCount--;
          }, 1000);
      },
      immediate: true, // This ensures the watcher is triggered upon creation
    },
  },
};
</script>